<template>
<div>
  <h2>
    Password:
  </h2>
  <div>
    <form v-on:submit.prevent="submitForm">
      <div class="form-group text-left">
        <label class="custom-label control-label">Password</label>
        <input class="form-control password-field" type="password" name="password" v-model.trim="password">
        <button class="btn btn-primary" type="submit"> Submit</button>
        </div>
    </form>
    <router-link to="/">Home</router-link>
  </div>

</div>
</template>

<script>
import {adminChange} from "../sharedVars";
export default {
  name: "Protected",
  data(){
    return{
      password: null
    }
  },
  methods: {
    submitForm(){
      if(this.password === "admin123"){
        adminChange.set();
        this.$router.push('/newcand')
      }
    }
  }
}
</script>

<style scoped>

</style>